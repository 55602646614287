import React, { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { Route, Routes, useLocation } from "react-router-dom";
import { Router } from "react-router-dom";
// import {Router}
//css
import "./App.css";
//translate
import "./i18n";
//bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import PreLoader from "./Components/PreLoader/PreLoader";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { languages } from "./languages";

//=================AdminRegister&Login=======
import AdminLogin from "./dashboard/Admin/Login";

// import Login from "./Pages/Auth/Login/Login";

// import Register from "./Pages/Auth/Register/Register";
// import ForgetPassword from "./Pages/Auth/ForgetPassword/ForgetPassword";
// import ForgetPassword2 from "./Pages/Auth/ForgetPassword/ForgetPasswordStep2";
import CheckOTP from "./Pages/Auth/OTP/OTP";
import Profile from "./Pages/Auth/Profile/Profile";
import Address from "./Components/Address/Address";


//=================Dashboard======================
import Dashboard from "./dashboard/App";
//=================Shipment======================
import ShipmentProcess from "./Pages/ShipmentProcess/ShipmentProcess";

//============================================================================
import SharedLayout from "./Pages/SharedLayout/SharedLayout";
import Notification from "./Components/Notification/Notification";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
//============================================================================
import Error from "./Pages/Error/Error404";
import { useDispatch } from "react-redux";
import { clearCart } from "./redux/cartSlice";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Blog from "./Components/Blog/Blog";

const Home = React.lazy(() => import("./Pages/Home/Home"));

const ActivityContent = React.lazy(() =>
  import("./Pages/OurActivities/ActivityContent/ActivityContent")
);
const Login = React.lazy(() => import("./Pages/Auth/Login/Login"));
const Register = React.lazy(() => import("./Pages/Auth/Register/Register"));
const ForgetPassword2 = React.lazy(() =>
  import("./Pages/Auth/ForgetPassword/ForgetPasswordStep2")
);
const ForgetPassword = React.lazy(() =>
  import("./Pages/Auth/ForgetPassword/ForgetPassword")
);
const OurAgents = React.lazy(() => import("./Pages/OurAgents/OurAgents"));
const Products = React.lazy(() => import("./Pages/Products/Products"));
const Product = React.lazy(() => import("./Pages/Products/Product/Product"));
const Categories = React.lazy(() => import("./Pages/Categories/Categories"));
const Category = React.lazy(() =>
  import("./Pages/Categories/Category/Category")
);
const Bites = React.lazy(() => import("./Pages/Bites/Bites"));
const BiteContent = React.lazy(() =>
  import("./Pages/Bites/BiteContent/Bite_Content")
);
const AboutUs = React.lazy(() => import("./Pages/AboutUs/AboutUs"));
const ContactUs = React.lazy(() => import("./Pages/ContactUs/ContactUs"));
const Partners = React.lazy(() => import("./Pages/Partners/Partners"));
const Partner = React.lazy(() => import("./Pages/Partners/Partner/Partner"));

const Offers = React.lazy(() => import("./Pages/Offers/Offers"));
const Offer = React.lazy(() => import("./Pages/Offers/Offer/Offer"));

// const OurNew = React.lazy(() => import("./Pages/OurNew/OurNew"));
const PrivacyPolicy = React.lazy(() =>
  import("./Pages/PrivacyPolicy/PrivacyPolicy")
);
function App() {
  var AgentID;
  var isAgentOnline;
  let token = localStorage.getItem("token");
  if (token) {
    var decodedToken = jwt_decode(token);
    AgentID = decodedToken._id;
    let currentDate = new Date();

    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      isAgentOnline = false;
    } else {
      isAgentOnline = true;
    }
  }

  //===============================Translate============================
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  useEffect(() => {
    document.body.dir = currentLanguage.dir;
  }, [currentLanguage, t]);
  //=========================================================================
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      location.pathname === "/shipmentProcess/shipmentproducts" ||
      location.pathname === "/shipmentProcess/cart"
    ) {
    } else {
      dispatch(clearCart());
    }
  }, [location]);

  //=========================================================================
  return (
    <div className="App" style={{ overflow: "hidden" }}>
      {location.pathname !== "/agent/login" &&
        location.pathname !== "/agent/register" &&
        !location.pathname.includes("/dashboard") && <Header />}
      {localStorage.getItem("token") && <Notification />}
      <ScrollToTop />
      <Routes>
        {/* Agent Pages*/}
        <Route
          path="/agent/login"
          element={
            <React.Suspense fallback={<PreLoader />}>
              <Login />
            </React.Suspense>
          }
        />
        <Route
          path="/agent/register"
          element={
            <React.Suspense fallback={<PreLoader />}>
              <Register />
            </React.Suspense>
          }
        />
        <Route
          path="/agent/forgetpassword"
          element={
            <React.Suspense fallback={<PreLoader />}>
              <ForgetPassword />
            </React.Suspense>
          }
        />
        <Route
          path="/agent/forgetpasswordstep-2"
          element={
            <React.Suspense fallback={<PreLoader />}>
              <ForgetPassword2 />
            </React.Suspense>
          }
        />
        <Route path="/agent/OTP" element={<CheckOTP />} />
        {/* ========================Main Page========================= */}
        <Route path="/" element={<SharedLayout />}>
          <Route
            index
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Home />
              </React.Suspense>
            }
          />

          <Route
            path="ourActivities/:activityId"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <ActivityContent />
              </React.Suspense>
            }
          />
          <Route
            path="products"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Products />
              </React.Suspense>
            }
          />
           <Route
            path="address"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Address />
              </React.Suspense>
            }
          />
          <Route
            path="blog"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Blog />
              </React.Suspense>
            }
          />
          <Route
            path="products/:productId"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Product />
              </React.Suspense>
            }
          />
          <Route
            path="categories"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Categories />
              </React.Suspense>
            }
          />
          <Route
            path="categories/:categoryId"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Category />
              </React.Suspense>
            }
          />
          <Route
            path="bites"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Bites />
              </React.Suspense>
            }
          />
          <Route
            path="bites/:biteId"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <BiteContent />
              </React.Suspense>
            }
          />
          <Route
            path="aboutUs"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <AboutUs />
              </React.Suspense>
            }
          />
          <Route
            path="contactUs"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <ContactUs />
              </React.Suspense>
            }
          />
          <Route
            path="ourAgents"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <OurAgents />
              </React.Suspense>
            }
          />
          <Route
            path="offers"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Offers />
              </React.Suspense>
            }
          />
          <Route
            path="offers/:offerId"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Offer />
              </React.Suspense>
            }
          />
          <Route
            path="partners"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Partners />
              </React.Suspense>
            }
          />
          <Route
            path="partners/:partnerId"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Partner />
              </React.Suspense>
            }
          />
          <Route
            path="privacypolicy"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <PrivacyPolicy />
              </React.Suspense>
            }
          />

          <Route path="*" element={<Error />} />
          <Route path="/agent/profile" element={<Profile />} />
          <Route
            path="shipmentProcess/*"
            element={token ? <ShipmentProcess /> : <Home />}
          />
        </Route>
        {/* ================================================= */}

        <Route
          path="/dashboard/*"
          element={
            localStorage.getItem("admin-token") ? (
              <Dashboard online={isAgentOnline} agent={AgentID} />
            ) : (
              <AdminLogin />
            )
          }
        />
      </Routes>
      {location.pathname !== "/agent/login" &&
        location.pathname !== "/agent/register" &&
        !location.pathname.includes("/dashboard") && <Footer />}
    </div>
  );
}
export default App;
